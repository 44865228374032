import { useEffect, useState } from 'react';

export const TitlePage = () => {
    const [version, setVersion] = useState(null);

    useEffect(() => {
        fetch('/data/title.json')
            .then(response => response.json())
            .then(setVersion);
    }, []);

    if (!version) return null;

    return (
        <div className="title-page">
            <h1>
                Lieblingsrezepte
                <br />
                der Familie Hanßen
            </h1>
            <img src="icon.svg" alt="Logo" />
            <p>lieblingsrezepte.familie-hanssen.de</p>
            <p className="version">
                {version.date}
                <br />
                Version: {version.version}
            </p>
        </div>
    );
};
