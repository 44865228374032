import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Monkey patch to trigger a `pushState` event, which is not built in originally.
(function (history) {
    const pushState = history.pushState;

    history.pushState = function (state) {
        if (typeof history.onpushstate === 'function') {
            history.onpushstate({ state });
        }

        const event = new Event('pushState');
        event.arguments = arguments;
        window.dispatchEvent(event);

        return pushState.apply(history, arguments);
    };
})(window.history);

// Fix full height via CSS for mobile devices
const documentHeight = () => {
    const doc = document.documentElement;

    doc.style.setProperty('--doc-width', '100px');
    doc.style.setProperty('--doc-height', '100px');

    doc.style.setProperty('--doc-width', `${window.innerWidth}px`);
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
};

window.addEventListener('resize', documentHeight);

documentHeight();

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
