import './styles/main.scss';

import { createContext, useEffect, useState } from 'react';
import { Router } from './Router';

export const DataContext = createContext({});

const App = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        fetch('/data/index.json')
            .then(response => response.json())
            .then(index => setData(prevState => ({ ...prevState, index })));

        fetch('/data/order.json')
            .then(response => response.json())
            .then(order => setData(prevState => ({ ...prevState, order })));

        fetch('/data/registry.json')
            .then(response => response.json())
            .then(registry => setData(prevState => ({ ...prevState, registry })));
    }, []);

    return (
        <DataContext.Provider value={data}>
            <Router />
        </DataContext.Provider>
    );
};

export default App;
