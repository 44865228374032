const {
    AMOUNTS,
    UNITS,
    AMOUNTS_REVERSE,
    UNDIVIDABLE_UNITS,
    UNDIVIDABLE_INGREDIENTS,
    UP_SCALABLE_UNITS,
    DOWN_SCALABLE_UNITS,
    UNIT_PLURALIZATION,
    INGREDIENT_PLURALIZATION
} = require('./constants');

const isNumeric = string => {
    return /^-?\d+$/.test(string);
};

const round = (num, precision = 4) => {
    return parseFloat(num.toFixed(precision));
};

const stringToFloat = string => parseFloat(string.replace(',', '.'));

const extractAmount = ingredient => {
    const ingredientPartsOriginal = ingredient.split(' ');

    const rangeMatch = ingredientPartsOriginal[0].match(/^(\d*(?:,\d+)?)-(\d*(?:,\d+)?)/); // i.e. 1-2 TL Salz

    if (rangeMatch) {
        return [
            [stringToFloat(rangeMatch[1]), stringToFloat(rangeMatch[2])],
            ingredientPartsOriginal.slice(1).join(' ')
        ];
    }

    const ingredientParts = ingredient.replace(',', '.').split(' ');

    if (isNumeric(ingredientParts[0]) && AMOUNTS[ingredientParts[1]]) {
        // has two amounts i.e. "1 1/2"
        return [
            round(parseFloat(ingredientParts[0]) + AMOUNTS[ingredientParts[1]]),
            ingredientPartsOriginal.slice(2).join(' ')
        ];
    } else {
        const amount = AMOUNTS[ingredientParts[0]] ?? parseFloat(ingredientParts[0]);

        return isNaN(amount) ? [null, ingredient] : [amount, ingredientPartsOriginal.slice(1).join(' ')];
    }
};

const extractUnit = ingredientWithoutAmount => {
    const ingredientParts = ingredientWithoutAmount.split(' ');
    const hasUnit = UNITS.includes(ingredientParts.slice(0, 1).join(' '));
    const hasTwoWordUnit = UNITS.includes(ingredientParts.slice(0, 2).join(' '));
    return hasTwoWordUnit
        ? [ingredientParts.slice(0, 2).join(' '), ingredientParts.slice(2).join(' ')]
        : hasUnit
        ? [ingredientParts.slice(0, 1).join(' '), ingredientParts.slice(1).join(' ')]
        : [null, ingredientWithoutAmount];
};

const removeTrailingZeros = amount => {
    while (amount.includes(',') && amount.at(-1) === '0') {
        amount = amount.slice(0, -1);
    }

    if (amount.at(-1) === ',') amount = amount.slice(0, -1);

    return amount;
};

export const scaleUnit = (amount, unit) => {
    //if (amount > 1000) console.log(amount, unit, UP_SCALABLE_UNITS[unit]);

    /*// ensure 1/2 kg and 500 g are transformed uniquely into ½ kg
    if (UP_SCALABLE_UNITS[unit] && AMOUNTS_REVERSE[amount / 1000]) {
        amount = amount / 1000;
        unit = UP_SCALABLE_UNITS[unit];
    }*/

    if (amount < 1 && DOWN_SCALABLE_UNITS[unit] && !AMOUNTS_REVERSE[amount]) {
        return scaleUnit(amount * 1000, DOWN_SCALABLE_UNITS[unit]);
    } else if (amount >= 1000 && UP_SCALABLE_UNITS[unit]) {
        return scaleUnit(amount / 1000, UP_SCALABLE_UNITS[unit]);
    }

    // round 933 -> 930
    let amountRounded = round(amount / 100, 1) * 100;
    let delta = Math.abs(1 - amountRounded / amount);

    const maxDelta = 0.025;

    if (amount % 5 !== 0 && amount !== amountRounded && delta < maxDelta) {
        return { amount: amountRounded, unit };
    }

    // round 1.333 kg -> 1.33 kg
    amountRounded = round(amount, 2);
    delta = Math.abs(1 - amountRounded / amount);

    if (amount % 5 !== 0 && amount !== amountRounded && delta < maxDelta) {
        return { amount: amountRounded, unit };
    }

    return { amount, unit };
};

export const pluralizeUnit = (amount, unit) => {
    if (amount > 1 && UNIT_PLURALIZATION[unit]) {
        return UNIT_PLURALIZATION[unit];
    }

    return unit;
};

export const pluralizeName = (amount, name) => {
    if (amount > 1 && INGREDIENT_PLURALIZATION[name]) {
        return INGREDIENT_PLURALIZATION[name];
    }

    return name;
};

export const prettyFormatAmount = (amount, unit, ingredient) => {
    return !amount
        ? ''
        : AMOUNTS_REVERSE[amount]
        ? AMOUNTS_REVERSE[amount]
        : UNDIVIDABLE_UNITS.includes(unit) || UNDIVIDABLE_INGREDIENTS.includes(ingredient)
        ? Math.round(amount)
        : ['kg', 'l'].includes(unit)
        ? removeTrailingZeros(amount.toFixed(3).replace('.', ','))
        : amount > 5
        ? Math.round(amount)
        : amount.toFixed(amount % 1 === 0 ? 0 : 1).replace('.', ',');
};

export const prettyFormatAmountToNumber = amount => {
    if (AMOUNTS[amount]) return AMOUNTS[amount];
    return parseFloat(`${amount}`.replace(',', '.'));
};

export const mapIngredient = ingredient => {
    const [amount, remaining] = extractAmount(ingredient);

    const [unit, name] = extractUnit(remaining);

    //console.log(amount, '|', remaining, '->', amount, unit, '|', name);

    return {
        amount,
        unit,
        name
    };
};
