import { useMemo, useState } from 'react';
import classNames from 'classnames';

export const Sidebar = ({
    recipes,
    favorites,
    isFavorite,
    active,
    visible,
    onSelect,
    setFilterbarVisible,
    filterCount
}) => {
    const [term, setTerm] = useState('');
    const [activeResultIndex, setActiveResultIndex] = useState(null);

    const hasSearchTerm = useMemo(() => term && term !== '', [term]);

    const handleSearchChange = event => {
        const newTerm = event.target.value.trim().toLowerCase();

        setTerm(newTerm);

        setActiveResultIndex(newTerm.length > 0 ? 0 : null);
    };

    const handleKeyDown = event => {
        const key = event.key;

        if (key === 'Enter') {
            window.history.pushState({}, '', `/${recipesFiltered[activeResultIndex].link}`);
            onSelect();
            return;
        }

        if (key === 'Escape') {
            resetSearch();
            return;
        }

        if (key === 'ArrowDown' || key === 'ArrowUp') {
            event.preventDefault();

            if (!hasSearchTerm) return;

            setActiveResultIndex(current => {
                const newIndex = key === 'ArrowDown' ? current + 1 : current - 1;
                if (newIndex < 0) return recipesFiltered.length - 1;
                if (newIndex >= recipesFiltered.length) return 0;
                return newIndex;
            });
        }
    };

    const resetSearch = () => {
        setTerm('');
        setActiveResultIndex(null);
    };

    const recipesFiltered = useMemo(() => {
        if (!recipes) return [];

        return term === '' ? recipes : recipes.filter(item => item.name.toLowerCase().includes(term));
    }, [recipes, term]);

    const handleRecipeItemClick = (link, index) => {
        if (activeResultIndex !== null) setActiveResultIndex(index);
        window.history.pushState({}, '', `/${link}`);
        onSelect();
    };

    return (
        <div className={classNames('sidebar', { visible })}>
            <header className={classNames({ active: recipes })}>
                <div className="left">
                    <img src="icon.svg" alt="Logo" onClick={() => (document.location = '/')} />
                    <a className="title" href="/">
                        Lieblingsrezepte
                        <br />
                        <span>der Familie Hanßen</span>
                    </a>
                </div>

                {recipes ? (
                    <div className="search">
                        <input
                            value={term}
                            placeholder={recipes ? `Suche in ${recipes.length} Rezepten` : ''}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyDown}
                        />
                        <img
                            className={classNames({ hidden: !hasSearchTerm })}
                            src="/icons/xmark.svg"
                            alt="Suche löschen"
                            onClick={resetSearch}
                        />

                        <div className="button-wrapper">
                            <button onClick={() => setFilterbarVisible(visible => !visible)}>
                                erweiterter Filter{filterCount ? ` (${filterCount})` : ''}
                            </button>

                            {/*{filterCount ? (
                            <img
                                className={classNames({ hidden: !filterCount })}
                                src="/icons/xmark.svg"
                                alt="Filter löschen"
                                onClick={resetSearch}
                            />
                        ) : null}*/}
                        </div>
                    </div>
                ) : null}
            </header>

            {recipes ? (
                recipesFiltered.length > 0 ? (
                    <ul className="recipe-list">
                        {recipesFiltered.map(({ name, page, link }, index) => (
                            <li
                                key={link}
                                className={classNames({
                                    active: activeResultIndex !== null ? index === activeResultIndex : link === active
                                })}
                                onClick={() => handleRecipeItemClick(link, index)}
                            >
                                <a href={`/${link}`} onClick={event => event.preventDefault()}>
                                    {name}
                                    <span>{page}</span>
                                    {isFavorite(page) ? <img src="/icons/heart-solid.svg" alt="" /> : null}
                                </a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="no-matches">keine Rezepte gefunden</div>
                )
            ) : null}
        </div>
    );
};
