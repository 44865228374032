const UNITS = [
    'g',
    'kg',
    'ml',
    'l',
    'cl',
    'EL',
    'TL',
    'gestr. TL',
    'Stk.',
    'Pck.',
    'Msp.',
    'Bund',
    'Kopf',
    'Köpfe',
    'Kapseln',
    'Tasse',
    'Tassen',
    'Glas',
    'Gläser',
    'kleines Glas',
    'kleine Gläser',
    'großes Glas',
    'große Gläser',
    'Dose',
    'Dosen',
    'kleine Dose',
    'kleine Dosen',
    'große Dose',
    'große Dosen',
    'Becher',
    'Scheibe',
    'Scheiben',
    'Zehe',
    'Zehen',
    'Blatt',
    'Blätter',
    'Würfel',
    'Zweig',
    'Zweige',
    'Stängel',
    'Prise',
    'Prisen',
    'Schote',
    'Schoten',
    'Flasche',
    'Flaschen',
    'Schuss',
    'Stange',
    'Stangen',
    'Strang',
    'Stränge',
    'Stück',
    'Stücke',
    'etwas'
];

const UNIT_PLURALIZATION = {
    Tasse: 'Tassen',
    Glas: 'Gläser',
    'kleines Glas': 'kleine Gläser',
    'großes Glas': 'große Gläser',
    Dose: 'Dosen',
    'kleine Dose': 'kleine Dosen',
    'große Dose': 'große Dosen',
    Scheibe: 'Scheiben',
    Zehe: 'Zehen',
    Blatt: 'Blätter',
    Zweig: 'Zweige',
    Prise: 'Prisen',
    Schote: 'Schoten',
    Flasche: 'Flaschen',
    Stange: 'Stangen',
    Strang: 'Stränge',
    Stück: 'Stücke'
};

const UP_SCALABLE_UNITS = {
    g: 'kg',
    ml: 'l'
};

const DOWN_SCALABLE_UNITS = {
    kg: 'g',
    l: 'ml'
};

const AMOUNTS = {
    '1/8': 0.125,
    '1/4': 0.25,
    '1/3': 0.3,
    '1/2': 0.5,
    '2/3': 0.66,
    '3/4': 0.75,
    '⅛': 0.125,
    '¼': 0.25,
    '⅓': 0.3,
    '½': 0.5,
    '⅔': 0.66,
    '¾': 0.75,
    '1 ¼': 1.25,
    '1 ⅓': 1.3,
    '1 ½': 1.5,
    '1 ⅔': 1.66,
    '1 ¾': 1.75
};

const AMOUNTS_REVERSE = {
    0.125: '⅛',
    0.25: '¼',
    0.3: '⅓',
    0.5: '½',
    0.66: '⅔',
    0.75: '¾',
    1.25: '1 ¼',
    1.3: '1 ⅓',
    1.5: '1 ½',
    1.66: '1 ⅔',
    1.75: '1 ¾'
};

const FRACTION_SYMBOLS = {
    '1/8': '⅛',
    '1/4': '¼',
    '1/3': '⅓',
    '1/2': '½',
    '2/3': '⅔',
    '3/4': '¾'
};

const UNDIVIDABLE_INGREDIENTS = ['Ei', 'Eier'];
const UNDIVIDABLE_UNITS = ['TL', 'gestr. TL', 'EL', 'Pck.', 'Msp.'];

const INGREDIENT_PLURALIZATION = {
    Apfel: 'Äpfel',
    Birne: 'Birnen',
    Chilischote: 'Chilischoten',
    Ei: 'Eier',
    Frühlingszwiebel: 'Frühlingszwiebeln',
    Gemüsezwiebel: 'Gemüsezwiebeln',
    Karotte: 'Karotten',
    Kirschtomate: 'Kirschtomaten',
    Salatgurke: 'Salatgurken',
    Schote: 'Schoten',
    Tomate: 'Tomaten',
    Pflaume: 'Pflaumen',
    Zitrone: 'Zitronen',
    Zwiebel: 'Zwiebeln'
};

module.exports = {
    UNITS,
    UNIT_PLURALIZATION,
    UP_SCALABLE_UNITS,
    DOWN_SCALABLE_UNITS,
    AMOUNTS,
    AMOUNTS_REVERSE,
    FRACTION_SYMBOLS,
    UNDIVIDABLE_INGREDIENTS,
    UNDIVIDABLE_UNITS,
    INGREDIENT_PLURALIZATION
};
