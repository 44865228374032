import { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { sample } from 'lodash';
import classNames from 'classnames';
import { TitlePage } from './components/TitlePage';
import { Registry } from './components/Registry';
import { Recipe } from './components/Recipe';
import { Loader } from './components/Loader';
import { DataContext } from './App';
import { useFavorites } from './hooks/useFavorites';
import { Sidebar } from './components/Sidebar';
import { Filterbar } from './components/Filterbar';

export const Router = () => {
    const dataContext = useContext(DataContext);

    const { favorites, isFavorite, toggleFavorite, syncFavorites } = useFavorites();

    const [recipes, setRecipes] = useState(null);
    const [recipeName, setRecipeName] = useState(null);
    const [filterCount, setFilterCount] = useState(0);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [filterbarVisible, setFilterbarVisible] = useState(false);

    const getRecipeName = useCallback(() => document.location.pathname.slice(1), []);
    const updateRecipeName = useCallback(() => setRecipeName(getRecipeName()), [getRecipeName]);

    useLayoutEffect(() => {
        updateRecipeName();

        const handlePushState = event => setRecipeName(event.arguments[2].slice(1));

        window.addEventListener('pushState', handlePushState);
        window.addEventListener('popstate', updateRecipeName);

        return () => {
            window.removeEventListener('pushState', handlePushState);
            window.removeEventListener('popstate', updateRecipeName);
        };
    }, [updateRecipeName]);

    useEffect(() => {
        if (dataContext.order && !recipeName && !getRecipeName()) {
            window.history.pushState({}, '', `/${sample(dataContext.order)}`);
            setSidebarVisible(true);
        }
    }, [dataContext.order, getRecipeName, recipeName]);

    useEffect(() => {
        if (recipeName && dataContext.order && !['title', 'registry', ...dataContext.order].includes(recipeName)) {
            document.location = '/';
        }
    }, [dataContext.order, recipeName]);

    const Page = dataContext.order ? (
        recipeName === 'title' ? (
            <TitlePage />
        ) : recipeName === 'registry' ? (
            <Registry />
        ) : (
            <Recipe
                recipeName={recipeName}
                isFavorite={isFavorite}
                toggleFavorite={toggleFavorite}
                syncFavorites={syncFavorites}
            />
        )
    ) : (
        <Loader />
    );

    const isRecipePage = !['title', 'registry'].includes(recipeName);

    return (
        <div className={classNames({ grid: isRecipePage })}>
            <img
                className="menu-icon"
                src="icons/menu.svg"
                alt="Menü anzeigen"
                onClick={() => setSidebarVisible(true)}
            />
            {isRecipePage ? (
                <>
                    <Sidebar
                        recipes={recipes}
                        favorites={favorites}
                        isFavorite={isFavorite}
                        active={recipeName}
                        visible={sidebarVisible}
                        onSelect={() => {
                            setSidebarVisible(false);
                            setFilterbarVisible(false);
                        }}
                        setFilterbarVisible={setFilterbarVisible}
                        filterCount={filterCount}
                    />
                    <Filterbar
                        favorites={favorites}
                        isFavorite={isFavorite}
                        hidden={!sidebarVisible}
                        visible={filterbarVisible}
                        setRecipes={setRecipes}
                        setFilterCount={setFilterCount}
                    />
                </>
            ) : null}
            <div
                className={classNames('overlay', { sidebarVisible, filterbarVisible })}
                onClick={() => {
                    setSidebarVisible(false);
                    setFilterbarVisible(false);
                }}
            />
            <div className="content">{Page}</div>
        </div>
    );
};
