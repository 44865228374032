import classNames from 'classnames';

export const Modal = ({ visible, onConfirm, onCancel, onClose, title, confirmDisabled, children }) => {
    return (
        <div className={classNames('modal', { visible })} onClick={onClose}>
            <div className="wrapper" onClick={event => event.stopPropagation()}>
                <h2>{title}</h2>

                <div className="content">{children}</div>

                <div className="buttons">
                    {onCancel ? <button onClick={onCancel}>Abbrechen</button> : null}
                    <button onClick={onConfirm} disabled={confirmDisabled}>
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
};
