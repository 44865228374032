import { Modal } from './Modal';
import { useState } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import { handleAxiosError } from '../utils/axios';
import { ACCESS_TOKEN, CURRENT_USER } from '../hooks/useFavorites';

const emailValidRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LoginModal = ({ visible, onClose, syncFavorites, setCurrentUser }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(null);

    const handleLoginConfirm = () => {
        const currentErrors = {};
        const emailSanitized = email.trim().toLowerCase();
        const passwordSanitized = password.trim();

        if (emailSanitized.length === 0) {
            currentErrors.email = '(darf nicht leer sein)';
        } else if (!emailSanitized.match(emailValidRegex)) {
            currentErrors.email = '(ist ungültig)';
        }

        if (passwordSanitized.length < 4) {
            currentErrors.password = '(ist zu kurz)';
        } else if (passwordSanitized.length > 32) {
            currentErrors.password = '(ist zu lang)';
        }

        setErrors(currentErrors);

        if (currentErrors.email || currentErrors.password) return;

        handleLoginFormClose();
        handleLogin(email, password);
    };

    const handleLogin = (email, password) => {
        setIsLoading(true);

        axios
            .post('/users', { email, password })
            .then(({ data: { accessToken } }) => {
                localStorage.setItem(ACCESS_TOKEN, accessToken);
                axios.defaults.headers.accessToken = accessToken;

                localStorage.setItem(CURRENT_USER, email);
                setCurrentUser(email);

                syncFavorites();
                setLoginSuccess(email);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    alert('Das Passwort stimmt leider nicht!');
                } else {
                    alert('Sorry, das hat nicht geklappt. Bitte versuche es später erneut.');
                }
                handleAxiosError(error);
            })
            .finally(() => setIsLoading(false));
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter') handleLoginConfirm();
    };

    const handleLoginSuccessClose = () => setLoginSuccess(null);

    const handleLoginFormClose = () => {
        onClose();
        setErrors({});
        setEmail('');
        setPassword('');
    };

    const { email: emailError, password: passwordError } = errors;

    return (
        <>
            <Modal
                visible={visible}
                onConfirm={handleLoginConfirm}
                onCancel={handleLoginFormClose}
                onClose={handleLoginFormClose}
                title="Anmelden / Registrieren"
                confirmDisabled={isLoading}
            >
                <p>
                    Melde dich an, um Favoriten zu speichern und zwischen Geräten zu teilen.
                    <br />
                    Wenn noch kein Account mit dieser E-Mail Adresse existiert, wird ein neuer angelegt.
                </p>

                <form className={classNames({ loading: isLoading })}>
                    <span className={classNames('group', { error: emailError })}>
                        <label>
                            E-Mail
                            <span>{emailError}</span>
                        </label>
                        <input
                            id="email"
                            value={email}
                            disabled={isLoading}
                            onChange={event => setEmail(event.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </span>

                    <span className={classNames('group', { error: passwordError })}>
                        <label>
                            Password
                            <span>{passwordError}</span>
                        </label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            disabled={isLoading}
                            onChange={event => setPassword(event.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </span>
                </form>
            </Modal>

            <Modal
                visible={loginSuccess}
                onConfirm={handleLoginSuccessClose}
                onClose={handleLoginSuccessClose}
                title="Anmelden / Registrieren"
            >
                <p>Du bist jetzt als {loginSuccess} angemeldet.</p>
            </Modal>
        </>
    );
};
