import classNames from 'classnames';
import { Fragment, useContext } from 'react';
import { DataContext } from '../App';

export const Registry = () => {
    const { index, order, registry } = useContext(DataContext);

    if (!index || !order || !registry) return null;

    //console.log(JSON.stringify(registry, null, 2));

    return (
        <div className="registry">
            {registry.filter(({name}) => name === 'Alphabetisch').map(group =>
                group.items[0].items ? (
                    <Fragment key={group.name}>
                        <h2>{group.name}</h2>
                        <hr />
                        {group.items.map(group => (
                            <RegistryGroup key={group.name} index={index} order={order} group={group} subGroup={true} />
                        ))}
                    </Fragment>
                ) : (
                    <RegistryGroup index={index} order={order} key={group.name} group={group} />
                )
            )}
        </div>
    );
};

const RegistryGroup = ({ index, order, group, subGroup = false }) => {
    if (!index || !order) return null;

    return (
        <div className={classNames('group', { 'sub-group': subGroup })}>
            {/*{subGroup ? (
                <h3>{group.name}</h3>
            ) : (
                <>
                    <h2>{group.name}</h2>
                    <hr />
                </>
            )}*/}

            {group.items.map(page => {
                const name = index[page - 1];
                const link = order[page - 1];

                return (
                    <div key={name}>
                        <a href={`/#/${link}`}>
                            <span className="name">{name}</span>
                            <span className="dots" />
                            <span className="page">{page}</span>
                        </a>
                    </div>
                );
            })}
        </div>
    );
};
