import {
    pluralizeName,
    pluralizeUnit,
    prettyFormatAmount,
    prettyFormatAmountToNumber,
    scaleUnit
} from '../utils/recipe-parsing';
import { Fragment, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { AMOUNTS_REVERSE, FRACTION_SYMBOLS } from '../utils/constants';
import classNames from 'classnames';
import { compact } from 'lodash';
import { intersection } from 'lodash/array';
import { Loader } from './Loader';
import { DataContext } from '../App';
import { LoginModal } from './LoginModal';
import { ACCESS_TOKEN, CURRENT_USER } from '../hooks/useFavorites';

export const Recipe = ({ recipeName, isFavorite, toggleFavorite, syncFavorites }) => {
    const { index, order } = useContext(DataContext);
    const [recipe, setRecipe] = useState(null);
    const [loginModelOpen, setLoginModalOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    useLayoutEffect(() => {
        setCurrentUser(localStorage.getItem(ACCESS_TOKEN) ? localStorage.getItem(CURRENT_USER) : null);
    }, []);

    useEffect(() => {
        if (!recipeName) return;

        setRecipe(null);

        fetch(`/data/recipes/${recipeName}.json`)
            .then(response => response.json())
            .then(setRecipe);
    }, [recipeName]);

    useEffect(() => {
        if (recipe) {
            document.title = `Lieblingsrezepte - ${recipe.name}`;
            Array.from(document.getElementsByTagName('meta')).filter(
                meta => meta.getAttribute('property') === 'og:title'
            )[0].content = recipe.name;
            syncFavorites();
        }
    }, [recipe, syncFavorites]);

    useEffect(() => {
        const handleClick = event => {
            // only handle click on anchors in recipe container
            if (event.target.nodeName !== 'A' || !event.target.closest('.recipe')) return;

            if (event.target.href.startsWith(document.location.origin)) {
                const recipeName = event.target.href.split('/').slice(-1)[0];
                window.history.pushState({}, '', `/${recipeName}`);
                event.preventDefault();
            }
        };

        window.addEventListener('click', handleClick);

        return () => window.removeEventListener('click', handleClick);
    }, []);

    if (!index || !order || !recipe) return <Loader />;

    //console.log(recipe);

    const ingredientsLists = recipe.recipe.filter(item => item.type === 'ingredients');
    const directionsLists = recipe.recipe.filter(item => item.type !== 'ingredients');

    /*console.log(JSON.stringify(ingredientsLists, null, 4));
    console.log(JSON.stringify(directionsLists, null, 4));*/

    const isEven = recipe.page % 2 === 0;

    const columns = !(recipe.servings && typeof recipe.servings === 'object' && recipe.servings.length > 1);

    return (
        <div className="recipe-wrapper">
            <div className={classNames('recipe', recipe.layouts, { even: isEven, odd: !isEven })}>
                <h1 className="recipe-header">{recipe.name}</h1>

                {currentUser ? (
                    <img
                        className="favorite"
                        src={`icons/heart-${isFavorite(recipe.page) ? 'solid' : 'regular'}.svg`}
                        title={isFavorite(recipe.page) ? 'Favorit' : 'zu Favoriten hinzufügen'}
                        alt={isFavorite(recipe.page) ? 'Favorit' : 'zu Favoriten hinzufügen'}
                        onClick={() => toggleFavorite(recipe.page)}
                    />
                ) : null}

                <img
                    className="login"
                    src={`icons/user-${currentUser ? 'solid' : 'regular'}.svg`}
                    title={currentUser ? `Angemeldet als ${currentUser}` : 'Anmelden'}
                    alt={currentUser ? `Angemeldet als ${currentUser}` : 'Anmelden'}
                    onClick={() => setLoginModalOpen(true)}
                />

                {columns ? null : <h2>Zutaten</h2>}

                <ConditionalDivWrapper condition={columns} className={classNames({ columns })}>
                    <Ingredients
                        order={order}
                        ingredientsLists={ingredientsLists}
                        servingsList={recipe.servings}
                        servingsLabel={recipe.servingsLabel}
                        columns={columns}
                    />
                    <Directions
                        order={order}
                        directionsLists={directionsLists}
                        columns={columns}
                        notes={recipe.notes}
                    />
                </ConditionalDivWrapper>

                <footer>{recipe.page}</footer>

                <LoginModal
                    visible={loginModelOpen}
                    onClose={() => setLoginModalOpen(false)}
                    syncFavorites={syncFavorites}
                    setCurrentUser={setCurrentUser}
                />
            </div>
        </div>
    );
};

const IngredientsHeader = ({ title, servings, servingsLabel, showServingsHeader }) => {
    const titleShort = title.startsWith('Zutaten ') ? title.replace('Zutaten ', '') : title;
    const servingsSuffix =
        servings && showServingsHeader
            ? `(${servings} ${servingsLabel ? servingsLabel : servings > 1 ? 'Portionen' : 'Portion'})`
            : null;

    return (
        <h2>
            {titleShort} {servingsSuffix}
        </h2>
    );
};

const DirectionsHeader = ({ title, nonOmissibles }) => {
    return (
        <h2>
            {title.startsWith('Zubereitung ') && !nonOmissibles.some(nonOmissible => title.endsWith(nonOmissible))
                ? title.replace('Zubereitung ', '')
                : title}
        </h2>
    );
};

const IngredientsTable = ({ order, ingredientsList, baseServings, servings, servingsLabel, showServingsHeader }) => {
    return (
        <table className={ingredientsList.items.some(item => item.amount) ? null : 'no-amount'}>
            <tbody>
                {servings && showServingsHeader ? (
                    <tr>
                        <th>{servings}</th>
                        <th>{servingsLabel ? servingsLabel : servings > 1 ? 'Portionen' : 'Portion'}</th>
                    </tr>
                ) : null}

                {ingredientsList.items.map((item, key) => (
                    <tr key={key}>
                        <IngredientColumns order={order} item={item} baseServings={baseServings} servings={servings} />
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const IngredientColumns = ({ order, item, baseServings, servings }) => {
    const { amount, unit, name } = item;

    const isCursive = name.includes('<em>');
    const nameSanitized = name.replaceAll('<em>', '').replaceAll('</em>', '');

    const hasMultiplier = baseServings && servings;
    const multiplier = hasMultiplier ? servings / baseServings : 1;

    //const leadingAmount = (amount && typeof amount === 'object' ? amount.at(0) : amount) * multiplier;
    const trailingAmount = (amount && typeof amount === 'object' ? amount.at(-1) : amount) * multiplier;

    const amountFormatted =
        amount && typeof amount === 'object'
            ? amount.map(item => prettyFormat(item * multiplier, unit, nameSanitized)).join('-')
            : prettyFormat(amount * multiplier, unit, nameSanitized);

    const formattedAmountAsNumber = prettyFormatAmountToNumber(amountFormatted);

    const { unit: scaledUnit } = scaleUnit(trailingAmount, unit);
    const pluralizedScaledUnit = pluralizeUnit(formattedAmountAsNumber, scaledUnit);

    const amountWithUnit = [amountFormatted, pluralizedScaledUnit].join(' ');

    let namePluralized = pluralizeName(formattedAmountAsNumber, nameSanitized);

    let nameHtml = isCursive ? `<em>${namePluralized}</em>` : namePluralized;

    return (
        <>
            <td>{amountWithUnit}</td>
            <td dangerouslySetInnerHTML={{ __html: setPagePrintReferences(nameHtml, order) }} />
        </>
    );
};

const ConditionalDivWrapper = ({ condition, children, className }) => {
    return condition ? <div className={className}>{children}</div> : children;
};

const Ingredients = ({ order, ingredientsLists, servingsList, servingsLabel, columns }) => {
    useLayoutEffect(() => {
        // skip if only one table exists
        if (document.querySelectorAll('.columns div:first-of-type table').length < 2) return;

        // get ingredient columns of all tables (but only amount+unit column, not ingredient name column)
        const columns = Array.from(document.querySelectorAll('.columns div:first-of-type td:first-of-type'));

        // reset all columns
        columns.forEach(td => (td.style.width = 'auto'));

        // get widest ingredient column width
        const newWidth = Math.ceil(Math.max(...columns.map(td => td.offsetWidth)));

        // set all columns to this width
        columns.forEach(td => (td.style.width = `${newWidth}px`));
    }, []);

    return (
        <div>
            {servingsList.map((servings, key) => {
                return (
                    <div key={key}>
                        <IngredientsScaled
                            order={order}
                            ingredientsLists={ingredientsLists}
                            baseServing={servingsList[0]}
                            servings={servings}
                            servingsLabel={servingsLabel}
                            columns={columns}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const IngredientsScaled = ({ order, ingredientsLists, baseServing, servings, servingsLabel, columns }) => {
    return ingredientsLists.map((ingredientsList, index) => (
        <Fragment key={index}>
            {columns ? (
                <IngredientsHeader
                    title={ingredientsList.title}
                    servings={servings}
                    servingsLabel={servingsLabel}
                    showServingsHeader={index === 0}
                />
            ) : null}
            <ConditionalDivWrapper condition={columns}>
                <IngredientsTable
                    order={order}
                    ingredientsList={ingredientsList}
                    baseServings={baseServing}
                    servings={columns ? null : servings}
                    servingsLabel={servingsLabel}
                    showServingsHeader={index === 0}
                />
            </ConditionalDivWrapper>
        </Fragment>
    ));
};

/**
 * Collects header suffixes which are used both in "Vorbereitung" AND "Zubereitung"
 */
const getNonOmissibleSuffixes = list => {
    const getPrefixedTitles = (list, prefix) =>
        list.map(item =>
            item.title && item.title.startsWith(prefix + ' ') ? item.title.replace(prefix + ' ', '') : null
        );

    return compact(intersection(getPrefixedTitles(list, 'Vorbereitung'), getPrefixedTitles(list, 'Zubereitung')));
};

const Directions = ({ order, directionsLists, columns, notes }) => {
    const nonOmissibles = getNonOmissibleSuffixes(directionsLists);

    return (
        <ConditionalDivWrapper condition={columns}>
            {directionsLists.map((directionsList, key) => (
                <DirectionsItem order={order} key={key} item={directionsList} nonOmissibles={nonOmissibles} />
            ))}
            {notes ? <div className="notes" dangerouslySetInnerHTML={{ __html: notes }} /> : null}
        </ConditionalDivWrapper>
    );
};

const DirectionsItem = ({ order, item, nonOmissibles }) => {
    return (
        <>
            {item.type === 'directions' ? (
                <DirectionsList order={order} title={item.title} list={item.items} nonOmissibles={nonOmissibles} />
            ) : null}
            {item.type === 'suggestions' ? (
                <SuggestionsList order={order} title={item.title} list={item.items} />
            ) : null}
            {item.type === 'text' ? <DirectionsText text={item.text} /> : null}
            {item.type === 'table' ? <DirectionsTable table={item.table} /> : null}
        </>
    );
};

const DirectionsList = ({ order, title, list, nonOmissibles }) => {
    return (
        <>
            <DirectionsHeader title={title} nonOmissibles={nonOmissibles} />
            <ol>
                {list.map((item, key) => (
                    <li
                        key={key}
                        dangerouslySetInnerHTML={{
                            __html: setPagePrintReferences(replaceFractionsWithSymbol(item), order)
                        }}
                    />
                ))}
            </ol>
        </>
    );
};

const setPagePrintReferences = (reference, order) => {
    let result = reference;

    Array.from(reference.matchAll(/<a[^>]*href="([^"]*)"[^>]*>(.*?)<\/a>/g)).forEach(match => {
        const linkTag = match[0];
        const reference = match[1].slice(1);
        const linkText = match[2];

        const page = order.indexOf(reference) + 1;

        if (!page) return;

        result = result.replace(linkText, `${linkTag}<span class="page-reference"> (Seite&nbsp;${page})</span>`);
    });

    return result;
};

const SuggestionsList = ({ order, title, list }) => {
    return (
        <>
            <DirectionsHeader title={title} nonOmissibles={[]} />
            <ul>
                {list.map((item, key) => {
                    return <li key={key} dangerouslySetInnerHTML={{ __html: setPagePrintReferences(item, order) }} />;
                })}
            </ul>
        </>
    );
};

const DirectionsText = ({ text }) => {
    return <p dangerouslySetInnerHTML={{ __html: replaceFractionsWithSymbol(text) }} />;
};

const DirectionsTable = ({ table }) => {
    return <table dangerouslySetInnerHTML={{ __html: replaceFractionsWithSymbol(table) }} />;
};

const replaceFractionsWithSymbol = text => {
    let result = text;

    const floatMatches = text.match(/[0-9],([0-9]+)/g); // matches i.e. 1,25

    if (floatMatches) {
        floatMatches.forEach(match => {
            //console.log(match);
            const [decimal, fraction] = match.split(',');
            const fractionSymbol = AMOUNTS_REVERSE[`0.${fraction}`];

            if (fractionSymbol) {
                result = result.replaceAll(match, `${decimal !== '0' ? decimal : ''} ${fractionSymbol}`);
            }
        });
    }

    const fractionMatches = text.match(/([0-9])\/([0-9]+)/g); // matches i.e. 1/2

    if (fractionMatches) {
        fractionMatches.forEach(match => {
            //console.log(match, FRACTION_SYMBOLS[match]);

            const fractionSymbol = FRACTION_SYMBOLS[match];

            if (fractionSymbol) {
                result = result.replaceAll(match, fractionSymbol);
            }
        });
    }

    return result;
};

const prettyFormat = (initialAmount, initialUnit, name) => {
    const { amount, unit } = scaleUnit(initialAmount, initialUnit, name);
    return prettyFormatAmount(amount, unit, name);
};
