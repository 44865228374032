import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import { handleAxiosError } from '../utils/axios';

export const ACCESS_TOKEN = 'accessToken';
export const CURRENT_USER = 'currentUser';

const BASE_URL_REMOTE = 'https://v220230111457215839.hotsrv.de:8080/api';
const BASE_URL_LOCAL = 'http://localhost:8080/api';

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? BASE_URL_REMOTE : BASE_URL_LOCAL;
axios.defaults.headers.accessToken = localStorage.getItem(ACCESS_TOKEN);

export const useFavorites = () => {
    const [favorites, setFavorites] = useState([]);

    const isFavorite = useCallback(page => favorites.find(item => item.page === page), [favorites]);

    const toggleFavorite = useCallback(
        page => {
            const action = isFavorite(page) ? 'remove' : 'add';

            axios({
                method: action === 'add' ? 'POST' : 'DELETE',
                url: '/favorites',
                data: { page }
            })
                .then(({ data }) => setFavorites(data))
                .catch(handleAxiosError);
        },
        [isFavorite]
    );

    const loadFavorites = useCallback(() => {
        if (!localStorage.getItem(ACCESS_TOKEN)) return;

        axios('/favorites')
            .then(({ data }) => setFavorites(data))
            .catch(handleAxiosError);
    }, []);

    useLayoutEffect(loadFavorites, [loadFavorites]);

    useEffect(() => {
        window.addEventListener('focus', loadFavorites);
        return () => window.removeEventListener('focus', loadFavorites);
    }, [loadFavorites]);

    return {
        favorites,
        toggleFavorite,
        isFavorite,
        syncFavorites: loadFavorites
    };
};
