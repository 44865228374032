import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { compact, flatten, groupBy, intersection, orderBy, uniq } from 'lodash';
import classNames from 'classnames';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { DataContext } from '../App';

export const Filterbar = ({ favorites, isFavorite, hidden, visible, setRecipes, setFilterCount }) => {
    const [tags, setTags] = useState(null);
    const [filterTemplates, setFilterTemplates] = useState(null);
    const [dropDownValue, setDropDownValue] = useState(undefined);
    const [expanded, setExpanded] = useState([]);
    const [includedTags, setIncludedTags] = useState([]);
    const [excludedTags, setExcludedTags] = useState([]);
    const [filterFavorites, setFilterFavorites] = useState(false);

    const { index, order, registry } = useContext(DataContext);

    useEffect(() => {
        fetch('/data/tags.json')
            .then(response => response.json())
            .then(setTags);

        fetch('/data/filter-templates.json')
            .then(response => response.json())
            .then(setFilterTemplates);
    }, []);

    useEffect(() => {
        if (favorites.length === 0) setFilterFavorites(false);
    }, [favorites.length]);

    const getRecipesByTag = useCallback(
        tag => {
            if (!tag) return;

            const [category, tagName] = tag.includes(' > ') ? tag.split(' > ') : [null, tag];

            if (!category) return registry.find(item => item.name === tagName).items;

            return (
                registry
                    .find(item => item.name === category && item.items.length > 0)
                    .items.find(item => item.name === tagName) ?? []
            ).items;
        },
        [registry]
    );

    const recipesFiltered = useMemo(() => {
        if (!index || !order || !registry) return null;

        const includedRecipes =
            includedTags.length === 0
                ? registry[0].items
                : intersection(
                      ...Object.values(
                          groupBy(
                              includedTags.map(tag => {
                                  const [category] = tag.includes(' > ') ? tag.split(' > ') : [null, tag];

                                  return {
                                      category,
                                      recipes: getRecipesByTag(tag)
                                  };
                              }),
                              'category'
                          )
                      ).map(group => uniq(compact(flatten(group.map(item => item.recipes)))))
                  );

        const excludedRecipes = uniq(compact(flatten(excludedTags.map(option => getRecipesByTag(option)))));

        return orderBy(
            includedRecipes
                .filter(page => !filterFavorites || isFavorite(page))
                .filter(page => !excludedRecipes.some(index => index === page))
                .map(page => ({
                    page,
                    name: index[page - 1],
                    link: order[page - 1]
                })),
            'name'
        );
    }, [index, order, registry, includedTags, excludedTags, getRecipesByTag, filterFavorites, isFavorite]);

    useEffect(() => {
        setRecipes(recipesFiltered);
    }, [recipesFiltered, setRecipes]);

    useEffect(() => {
        setFilterCount(includedTags.length + excludedTags.length);
    }, [excludedTags.length, includedTags.length, setFilterCount]);

    if (!tags || !registry || !filterTemplates) return null;

    const toggleExpanded = group => {
        if (expanded.includes(group)) {
            setExpanded(previous => previous.filter(item => item !== group));
        } else {
            setExpanded(previous => uniq([group, ...previous]));
        }
    };

    const toggleIncludedTag = tag => {
        if (includedTags.includes(tag)) {
            setIncludedTags(previous => previous.filter(item => item !== tag));
        } else {
            setIncludedTags(previous => uniq([tag, ...previous]));
            setExcludedTags(previous => previous.filter(item => item !== tag));
        }
    };

    const toggleExcludedTag = tag => {
        if (excludedTags.includes(tag)) {
            setExcludedTags(previous => previous.filter(item => item !== tag));
        } else {
            setExcludedTags(previous => uniq([tag, ...previous]));
            setIncludedTags(previous => previous.filter(item => item !== tag));
        }
    };

    const toggle = tag => {
        if (includedTags.includes(tag) || excludedTags.includes(tag)) {
            toggleExcludedTag(tag);
        } else {
            toggleIncludedTag(tag);
        }
    };

    const tagList = [];

    tags.forEach(tag => {
        const [category, tagName] = tag.includes(' > ') ? tag.split(' > ') : [null, tag];

        if (!category) return;

        const categoryItem = tagList.find(item => item.category === category);

        if (categoryItem) {
            categoryItem.items.push({ value: tag, label: tagName, items: [], isDisabled: false });
        } else {
            tagList.push({
                value: tag,
                category,
                label: category,
                items: [{ value: tag, label: tagName, items: [], isDisabled: false }]
            });
        }
    });

    const setTemplate = name => {
        const template = filterTemplates.find(template => template.label === name).value;
        if (template.expanded) setExpanded(template.expanded);
        setIncludedTags(template.includedTags);
        setExcludedTags(template.excludedTags);
        setDropDownValue(name);
    };

    const resetTemplate = () => {
        setIncludedTags([]);
        setExcludedTags([]);
        setDropDownValue(undefined);
    };

    return (
        <div className={classNames('filterbar', { hidden, visible })}>
            <h2>Filter</h2>

            {favorites.length > 0 ? (
                <h3 className="favorites" onClick={() => setFilterFavorites(active => !active)}>
                    <img src={`/icons/heart-${filterFavorites ? 'solid' : 'regular'}.svg`} alt="" />
                    nur Favoriten
                </h3>
            ) : null}

            <h3>
                Vorauswahl{' '}
                {includedTags.length > 0 || excludedTags.length > 0 ? (
                    <img src="/icons/xmark.svg" onClick={resetTemplate} alt="" />
                ) : null}
            </h3>

            <Dropdown
                options={filterTemplates.map(template => template.label)}
                onChange={name => setTemplate(name.label)}
                value={dropDownValue}
                placeholder="keine"
                arrowClosed={<img src="/icons/caret-right.svg" alt="" />}
                arrowOpen={<img className="open" src="/icons/caret-right.svg" alt="" />}
            />

            <div className="tags">
                {tagList.map(item => {
                    if (item.items.length === 0) return null;

                    return (
                        <Fragment key={item.label}>
                            <h3 className="accordion" onClick={() => toggleExpanded(item.label)}>
                                <img
                                    className={classNames({ expanded: expanded.includes(item.label) })}
                                    src="/icons/caret-right.svg"
                                    alt=""
                                    aria-hidden={true}
                                />
                                {item.label}
                            </h3>

                            <ul className={classNames({ expanded: expanded.includes(item.label) })}>
                                {item.items.map(option => (
                                    <li key={`${item.label}${option.label}`}>
                                        <button
                                            className={classNames('included', {
                                                active: includedTags.includes(option.value)
                                            })}
                                            onClick={() => toggleIncludedTag(option.value)}
                                        >
                                            <span>+</span>
                                        </button>
                                        <button
                                            className={classNames('excluded', {
                                                active: excludedTags.includes(option.value)
                                            })}
                                            onClick={() => toggleExcludedTag(option.value)}
                                        >
                                            <span>-</span>
                                        </button>
                                        <span className="label" onClick={() => toggle(option.value)}>
                                            {option.label}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};
