import { useEffect, useState } from 'react';
import classNames from 'classnames';

export const Loader = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => setVisible(true), 600);
    }, []);

    return (
        <div className={classNames('loader', { visible })}>
            <div className="lds-ripple">
                <div />
                <div />
            </div>
        </div>
    );
};
